// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../images/no_avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"img-circle\" ng-class=\"avatarClass\" ng-if=\"!userAvatar || !userAvatar.attachment_url\"> <img ng-src=\"{{userAvatar.attachment_url}}\" class=\"img-circle\" ng-class=\"avatarClass\" ng-if=\"userAvatar.attachment_url\"> ";
// Exports
var _module_exports = code;;
var path = '/shared/_user_avatar.html';
var angular = require('angular');
angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;