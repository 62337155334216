Application.Directives.directive('news', ['Setting',
  function (Setting) {
    return ({
      restrict: 'E',
      templateUrl: '/home/news.html',
      link ($scope, element, attributes) {
        // The admin blogpost
        $scope.homeBlogpost = null;

        // constructor
        const initialize = function () {
          Setting.get({ name: 'home_blogpost' }, function (data) {
            $scope.homeBlogpost = data.setting.value;
          });
        };

        // !!! MUST BE CALLED AT THE END of the directive
        return initialize();
      }
    });
  }
]);
