// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../images/arrow-left.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div> <section class=\"heading b-b\"> <div class=\"row no-gutter\"> <div class=\"col-md-1 col-sm-2 hidden-xs\"> <section class=\"heading-icon\"> <i class=\"fa fa-link\"></i> </section> </div> <div class=\"col-md-8 col-sm-10 b-l\"> <section class=\"heading-title\"> <h1 translate>{{ 'app.logged.profile_completion.confirm_your_new_account' }}</h1> </section> </div> </div> </section> <div class=\"row no-gutter\"> <div class=\"col-sm-12 col-md-12 b-r\"> <div class=\"row\"> <div class=\"col-md-offset-2 col-md-8 m-t-md\"> <completion-header-info on-error=\"onError\" user=\"user\" active-provider=\"activeProvider\"/> </div> </div> <div class=\"row col-md-2 col-md-offset-5 hidden-sm hidden-xs\" ng-hide=\"user.merged_at || hideNewAccountConfirmation()\"> <p class=\"font-felt fleche-left text-lg upper text-center\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"fleche-left visible-lg visible-md fleche-left-from-top\"/> <span class=\"or\" translate>{{ 'app.logged.profile_completion.or' }}</span> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"fleche-right visible-lg visible-md fleche-right-from-top\"/> </p> </div> <div class=\"row\"> <div class=\"col-md-6\"> <div class=\"m-lg panel panel-default bg-light pos-rlt\"> <div ng-class=\"{'disabling-overlay' : !!user.auth_token}\"> <div class=\"panel-body\"> <profile-form-option on-error=\"onError\" on-success=\"onSuccess\" user=\"user\" operator=\"currentUser\" active-provider=\"activeProvider\"/> </div> </div> </div> </div> <div class=\"row col-xs-2 col-xs-offset-5 hidden-md hidden-lg\" ng-hide=\"hideNewAccountConfirmation()\"> <p class=\"font-felt fleche-left text-lg upper text-center\"> <span class=\"or\" translate>{{ 'app.logged.profile_completion.or' }}</span> </p> </div> <div class=\"col-md-6 m-t-3xl-on-md\" ng-hide=\"user.merged_at || hideNewAccountConfirmation()\"> <ng-include src=\"'/profile/_token.html'\"></ng-include> </div> </div> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/profile/complete.html';
var angular = require('angular');
angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;